<button class="nav-toggle" (click)="toggleMenu()" [class.isOpen]="isOpen">
    <span class="line" [class.isOpen]="isOpen"></span>
    <span class="line" [class.isOpen]="isOpen"></span>
</button>


<nav class="navigation" [class.isOpen]="isOpen">
    <section class="nav-section">
        <h1 class="label">Contact</h1>
        <a class="link" href="mailto:hello@weareboundless.com">hello@weareboundless.com</a>
    </section>

    <section class="nav-section location-section">
        <h1 class="label">Locate us</h1>
        <span>Los Angeles, CA</span>
    </section>

    <section class="nav-section">
        <h1 class="label">Follow us</h1>
        <a href="https://www.linkedin.com/company/weboundless/" target="_blank" rel="noreferrer noopener">
            <img class="icon" alt="LinkedIn" src="/assets/icons/linkedin.svg" />
        </a>
        <a href="https://www.instagram.com/weareboundless.co/" target="_blank" rel="noreferrer noopener">
            <img class="icon" alt="Instagram" src="/assets/icons/instagram.svg" />
        </a>
        <a href="https://www.facebook.com/weareboundless.co/" target="_blank" rel="noreferrer noopener">
            <img class="icon" alt="Facebook" src="/assets/icons/facebook.svg" />
        </a>
        <a href="https://twitter.com/weboundless" target="_blank" rel="noreferrer noopener">
            <img class="icon" alt="Twitter" src="/assets/icons/twitter.svg" />
        </a>
    </section>
</nav>