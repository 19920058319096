<div class="bg-top"></div>
<div class="logo-container">
    <div class="ribbon-wrapper">
        <div class="month" *ngFor="let item of repeatEl">
            <template [ngTemplateOutlet]="logoTpl"></template>
        </div>
    </div>
</div>
<div class="caption">Immortalizing Champions</div>
<div class="bg-bottom"></div>

<ng-template #logoTpl>
    <div class="logo"></div>
</ng-template>