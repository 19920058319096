import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { MenuComponent } from './menu.component'

@NgModule({
  imports: [CommonModule],
  exports: [MenuComponent],
  declarations: [MenuComponent],
})
export class MenuModule {}
