import { Component } from '@angular/core'

@Component({
  selector: 'boundless-background',
  templateUrl: 'background.component.html',
  styleUrls: ['background.component.scss']
})

export class BackgroundComponent {
  public repeatEl = Array(30).fill(null)
}