import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { BackgroundComponent } from './background.component'

@NgModule({
  imports: [CommonModule],
  exports: [BackgroundComponent],
  declarations: [BackgroundComponent],
  providers: [],
})
export class BackgroundModule {}
