import { Component } from '@angular/core'

@Component({
  selector: 'boundless-menu',
  templateUrl: 'menu.component.html',
  styleUrls: [
    'menu.component.scss'
  ]
})

export class MenuComponent {
  isOpen: boolean = false ;

  public toggleMenu(){
    this.isOpen = !this.isOpen;
  }
}